import * as Yup from 'yup'

const ValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'support.form.name.validationShort')
    .max(50, 'support.form.name.validationLong')
    .required('support.form.name.validationRequired'),
  email: Yup.string()
    .email('support.form.email.validationInvalid')
    .required('support.form.email.validationRequired'),
  message: Yup.string()
    .min(2, 'support.form.message.validationShort')
    .max(4000, 'support.form.message.validationLong')
    .required('support.form.message.validationRequired'),
  agreement: Yup.bool()
    .oneOf([true])
})

export default ValidationSchema

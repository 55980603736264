import React from 'react'
import Support from '../../components/Support'
import { Location } from '@reach/router';
import SiteHeader from '../../components/SiteHeader'
import SiteFooter from '../../components/SiteFooter'
import SEO from '../../components/SEO'
import { useIntl } from 'gatsby-plugin-intl'

function support(props) {
    const intl = useIntl()
    return (
        <Location>
            {locationProps => (
                <>
                <SEO className="support" title={intl.formatMessage({ id: 'support.title' })} {...locationProps} 
                    description={intl.formatMessage({ id: "meta.support.description" })} 
                    ogpDesc={intl.formatMessage({ id: "meta.support.ogpDesc" })} 
                    />
                <SiteHeader {...locationProps} {...props} />
                <Support {...locationProps} {...props} />
                <SiteFooter {...locationProps} {...props} />
                </>
            )}
        </Location>
    )
}

export default support

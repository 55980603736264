import React, { Component } from 'react'

import { injectIntl, Link } from 'gatsby-plugin-intl'
import { animateScroll as scroll } from 'react-scroll'

import logo from '../img/logo@2x.png'
import angleUp from '../img/angle-up-solid.svg'
import Burger from './Burger'

class SiteHeader extends Component {
  constructor(props, context) {
    super(props, context)
    this.initialized = false
    this.state = {
      toTopShow: false, 
      menuActive: false, 
      height: null
    }
  }

  //props.pathは /ja/ のように末尾にスラッシュが付く
  //props.uriは /ja のように末尾スラッシュなし

  componentDidMount() {
    console.log("componentDidMount h")
    if (!this.initialized) {
        window.addEventListener('resize', this.updateDimensions)
        window.addEventListener('scroll', this.updateScroll)
        this.initialized = true
    }
  }

  updateDimensions = () => {
    if (window) {
      this.setState({
          height: window.innerHeight
      })
    }
  }

  componentWillUnmount() {
    console.log("componentwillunmount h")
    window.removeEventListener('resize', this.updateDimensions)
    window.removeEventListener('scroll', this.updateScroll)
    document.body.style.overflow = 'unset'
  }

  getScrollTop = () => {
    return document?.documentElement?.scrollTop || document?.body?.scrollTop || window?.pageYOffset || 0
  }

  updateScroll = (event) => {
    let newState = null
    const scrollTop = this.getScrollTop()
    if (this.state.menuActive && scrollTop === 0) {
      if (!newState) newState = {}
      newState.menuActive = false
    }
		if (scrollTop > 0) {
			if (!this.state.toTopShow) {
        if (!newState) newState = {}
        newState.toTopShow = true
      }
		}
		else {
			if (this.state.toTopShow) {
        if (!newState) newState = {}
        newState.toTopShow = false
      }
    }
    if (newState) this.setState(newState)
  }
  
  scrollToTop = () => {
    scroll.scrollToTop({ duration: 500, smooth: "easeInOut" })
  }

  isFrontPage = () => {
    const intl = this.props.intl
    return this.props.uri === "/" || this.props.uri === `/${intl.locale}`
  }

  currentPageClassForMenu = (slug) => {
    if (slug.length === 0) {
      return this.isFrontPage() ? 'current' : ''
    }
    else {
      const intl = this.props.intl
      slug += '/'
      const path = this.props.path.endsWith('/') ? this.props.path : this.props.path + '/'
      const slugWithLocale = `/${intl.locale}${slug}`
      //console.log(path, slug, slugWithLocale)
      return path.startsWith(slug) || path.startsWith(slugWithLocale) ? 'current' : ''
    }
  }

  render() {
    console.log("render h")
    const intl = this.props.intl
  
    let menuPropsHome = { to: "/" }
    let menuPropsMission = { to: "/mission" }
    let menuPropsSotd = { to: "/sotd" }
    let menuPropsNews = { to: "/category/news" }
    let menuPropsFaq = { to: "/category/faq" }
    let menuPropsSupport = { to: "/support" }

    if (intl.locale === 'ja') {
      menuPropsHome.title = "トップ"
      menuPropsMission.title = "我々のミッション"
      menuPropsSotd.title = "今日の1曲"
      menuPropsNews.title = "ニュース"
      menuPropsFaq.title = "FAQ"
      menuPropsSupport.title = "サポート"
    }

    let h = 0
    const navigationMenuClassName = this.state.menuActive ? "opened" : "closed"
    const mobileNavigationClassName = this.state.menuActive ? "opened" : "closed"
    const toTopClassName = this.state.toTopShow ? "show" : ""

    if (this.initialized) {
      h = window ? window.innerHeight : 0
      if (document) document.body.style.overflow = this.state.menuActive ? 'hidden' : 'unset'
    }

    return (
      <>
      <header id="header" className="mission" style={this.props.style}>
        <div id="headerRow">
          <div id="logoContainer">
            <h1 id="siteLogo" title="New Monaural"><Link to="/"><img src={logo} alt="New Monaural" id="logo" /></Link></h1>
          </div>
          <div id="headerNavigation">
          <ul>
            <li><Link {...menuPropsHome} className={this.currentPageClassForMenu('')}>TOP</Link></li>
            <li><Link {...menuPropsMission} className={this.currentPageClassForMenu('/mission')}>OUR MISSION</Link></li>
            <li><Link {...menuPropsSotd} className={this.currentPageClassForMenu('/sotd')}>SONG OF THE DAY</Link></li>
            <li><Link {...menuPropsNews} className={this.currentPageClassForMenu('/category/news')}>NEWS</Link></li>
            <li><Link {...menuPropsFaq} className={this.currentPageClassForMenu('/category/faq')}>FAQ</Link></li>
            <li><Link {...menuPropsSupport} className={this.currentPageClassForMenu('/support')}>SUPPORT</Link></li>
          </ul>
        </div>
        <div id="navigationMenu" className={navigationMenuClassName}>MENU
        <Burger
          onClick={() => this.setState({ menuActive: !this.state.menuActive })}
          burger="slider" 
          active={this.state.menuActive}
        />
        </div>
      </div>
      </header>
      <div id="mobileNavigation" className={mobileNavigationClassName} style={{height: this.state.menuActive ? h : 0}}>
        <Link {...menuPropsHome} style={{marginTop: (h - 60 - 432) / 2}}><div>TOP</div></Link>
        <Link {...menuPropsMission}><div>OUR MISSION</div></Link>
        <Link {...menuPropsSotd}><div>SONG OF THE DAY</div></Link>
        <Link {...menuPropsNews}><div>NEWS</div></Link>
        <Link {...menuPropsFaq}><div>FAQ</div></Link>
        <Link {...menuPropsSupport}><div>{intl.formatMessage({ id: 'support.title_native'})}</div></Link>
      </div>
      {
        this.props.removeToTop !== true ? 
        <div id="toTop" className={toTopClassName}><img src={angleUp} alt="TOP" onClick={this.scrollToTop} /></div> : 
        null
      }
      </>
    )
  }
}

export default injectIntl(SiteHeader)

import React, { Component } from 'react'
import facebook from '../img/facebook.svg'

class FacebookButton extends Component {
  constructor(props, context) {
    super(props, context)
  }

  openShareDialog = event => {
    event.preventDefault()
    event.nativeEvent.stopImmediatePropagation()
    const href = `https://www.facebook.com/share.php?u=https://www.newmonaural.com${this.props.pagePath}`
    window.open(href, 'FBwindow', 'width=650, height=450, menubar=no, toolbar=no, scrollbars=yes')
  }
  render() {
    return (
      <a onClick={event => this.openShareDialog(event)} className={this.props.className}>
        <img src={facebook} alt="Share to Facebook" />
      </a>
    )
  }
}

export default FacebookButton

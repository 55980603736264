import React, { Component } from 'react'

import { injectIntl, Link } from "gatsby-plugin-intl"

import logo from '../img/emotionale@2x.png'


import TwitterButton from './TwitterButton'
import FacebookButton from './FacebookButton'

import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"

const languageName = {
  en: "English",
  ja: "Japanese"
}

class SiteFooter extends Component {
  constructor(props, context) {
    super(props, context)
  }

  getTextFromIntl = (key) => {
    return this.props.intl.formatMessage({ id: key })
  }

  render() {
  
    const intl = this.getTextFromIntl

    const twitterTitle = intl('share.twitter.title')
  
    const isJP = this.props.intl.locale === 'ja'
    const langPath = isJP ? '' : 'en/'

    let menuPropsMission = { to: "/mission" }
    let menuPropsSotd = { to: "/sotd" }
    let menuPropsNews = { to: "/category/news" }
    let menuPropsFaq = { to: "/category/faq" }
    let menuPropsSupoort = { to: "/support" }
    let menuPropseEnquete = { to: "/information/infoforusersurvey" }
    let menuPropseTerms = { to: "/terms" }
    let menuPropsePrivacy = { to: "/privacy" }

    if (isJP) {
        menuPropsMission.title = "我々のミッション"
        menuPropsSotd.title = "今日の1曲"
        menuPropsNews.title = "ニュース"
        menuPropsFaq.title = "FAQ"
        menuPropsSupoort.title = "サポート"
        menuPropseEnquete.title = "アンケート"
        menuPropseTerms.title = "利用規約"
        menuPropsePrivacy.title = "プライバシーポリシー"
    }

    return (
    <footer>
      <div id="footerContainer">
      	<ul id="footerNavigation">
            <li><Link {...menuPropsMission}>OUR MISSION</Link></li>
            <li><Link {...menuPropsSotd}>SONG OF THE DAY</Link></li>
            <li><Link {...menuPropsNews}>NEWS</Link></li>
            <li><Link {...menuPropsFaq}>FAQ</Link></li>
            <li><Link {...menuPropsSupoort}>{intl('support.title_native')}</Link></li>
            { isJP ? <li><Link {...menuPropseEnquete}>アンケート</Link></li> : null }
            <li><Link {...menuPropseTerms}>{intl('terms.title')}</Link></li>
            <li><Link {...menuPropsePrivacy}>{intl('privacy.title')}</Link></li>
            <li><TwitterButton pagePath={"/" + langPath} shareTitle={twitterTitle} className="twitter" /></li>
            <li><FacebookButton pagePath={"/" + langPath} className="facebook" /></li>
      	</ul>
      	<ul id="languageSelector">
          <IntlContextConsumer>
          {({ languages, language: currentLocale }) =>
            languages.map(language => (
              <li key={language}>
              <a
                onClick={() => changeLocale(language)}
              >
                {languageName[language]}
              </a>
              </li>
            ))
          }
          </IntlContextConsumer>
      	</ul>
      	<p id="copy">
            <a href={"https://www.emotionale.jp/" + (isJP ? '' : 'lang=en')} className="emotionale"><img src={logo} alt="Emotionale" /></a>&copy; 2024
      	</p>
      </div>
    </footer>
    )
  }
}

export default injectIntl(SiteFooter)


import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

function SEO(props) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            siteUrlBase
          }
        }
      }
    `
  )
console.log(props)
  const { title, className, location, isTop, description, ogpDesc, ogp } = props

  const generateAlternateLinkForLang = (base, pathname, fromLang, toLang) => {
    let newPathname = null
    if (pathname.startsWith(`/${fromLang}`)) {
      newPathname = pathname.replace(`/${fromLang}`, `/${toLang}`)
    }
    else {
      newPathname = `/${toLang}${pathname}`
    }
    return base + newPathname
  }

  const intl = useIntl()
  return (
    <Helmet
      htmlAttributes={{
        lang: intl.locale, 
        prefix: 'og: http://ogp.me/ns# fb: http://ogp.me/ns/fb# article: http://ogp.me/ns/article#'
      }}
      title={title}
      titleTemplate={ isTop ? `%s` : `%s | ${site.siteMetadata.title}`}
      bodyAttributes={{ class: className }}
    >
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover" />
    <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
    <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
    <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
    <link rel="manifest" href="/favicon/site.webmanifest" />
    <link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#000000" />
    <link rel="shortcut icon" href="/favicon/favicon.ico" />
    <meta name="msapplication-TileColor" content="#000000" />
    <meta name="msapplication-config" content="/favicon/browserconfig.xml" />
    <meta name="theme-color" content="#ffffff" />
    <meta name="apple-itunes-app" content="app-id=1439882679" />
    {['ja', 'en'].map(language => (
      intl.locale !== language ? 
      <link key={language} rel="alternate" 
        href={ generateAlternateLinkForLang(site.siteMetadata.siteUrlBase, location.pathname, intl.locale, language) } 
        hreflang={language}>
      </link>
      :
      null
      ))
    }

    <meta http-equiv='X-UA-Compatible' content='IE=edge' />
    <meta name='Keywords' content={ intl.formatMessage({ id: "meta.keywords" }) } />
    <meta name='description' content={ description || intl.formatMessage({ id: 'meta.top.description' }) } />
    <meta name='twitter:card' content='summary_large_image' />
    <meta property='fb:app_id' content='1452466168120526' />
    <meta property='og:type' content='website' />
    <meta property='og:url' content={ `${site.siteMetadata.siteUrlBase}${location.pathname}` } />
    <meta property='og:locale' content={ intl.locale === 'ja' ? 'ja_JP' : 'en_US' } />
    <meta property='og:title' content={ isTop ? site.siteMetadata.title : `${title} | ${site.siteMetadata.title}` } />
    <meta property='og:description' content={ ogpDesc || intl.formatMessage({ id: "meta.top.ogpDesc" })} />
    <meta property='og:image' content={ ogp?.image || `${site.siteMetadata.siteUrlBase}/images/ogp.jpg`} />
    <meta property='og:image:width' content={ ogp?.width || '1200'} />
    <meta property='og:image:height' content={ ogp?.height || '630'} />
    <meta property='twitter:image' content={ ogp?.twitterImage || `${site.siteMetadata.siteUrlBase}/images/ogp.jpg` } />

    </Helmet>
  )
}

export default SEO

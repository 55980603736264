import React, { Component } from 'react'

import { Formik } from 'formik'
import validationSchema from '../classes/ValidationSchema'
import axios from "axios"
import { navigate } from "gatsby"

import '../css/bootstrap.min.scss'

import { injectIntl } from "gatsby-plugin-intl"

class Support extends Component {

    constructor(props, context) {
        super(props, context)
        this.state = {
            policyAgreed: false
        }
    }

    changeAgreement = (e) => {
        this.setState({ policyAgreed: e.target.checked === true })
    }

    getTextFromIntl = (key) => {
        return this.props.intl.formatMessage({ id: key })
    }

    render() {
        const intl = this.getTextFromIntl
        const titles = intl('support.form.title.options').split(',')
        const iOSVersions = intl('support.form.os.options').split(',')
        const AppVersions = intl('support.form.app.options').split(',')
        const required = intl('support.form.required')

        const pageTitle = intl('support.title').toUpperCase()
        const pageTitleNative = intl('support.title_native')

        //validationSchemaからはintl用のキー名を返すようにして、それでローカライズされたバリデーション文言を表示する
        
return(
    <div id="contactMain">
        <div id="contactContainer">
            <h2>
            { pageTitleNative.length !== 0 ? 
                <span title={pageTitleNative}>{pageTitle}</span>
                :
                <span>{pageTitle}</span>
            }
            </h2>
            <p>{intl('support.description')}</p>
            <div role="form" className="wpcf7" id="wpcf7-f6-p8-o1" lang="ja" dir="ltr">

    <Formik
      initialValues={{ name: '', email: '', message: '' }}
      validationSchema={validationSchema}
      initialValues = {{agreement: false}}
      onSubmit={
        (values, actions) => {
            const formData = new URLSearchParams();
            Object.entries(values).forEach(([key, value]) => {
                formData.append(key, value)
            })
            formData.append('form-name', 'support')

            actions.setSubmitting(true)
            axios({
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                data: formData,
                url: "/"
            }).then(r => {
                console.log(r)
                actions.resetForm()
                navigate('/Thankyou')
            }).catch(err => {
                console.log(err)
            })
            .finally(() => {
                console.log("finally")
                actions.setSubmitting(false)
            })
          }
      }
    >{({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty }) => (
        <form
            name="support"
            method="POST"
            noValidate={true}
            data-netlify="true"
            netlify-honeypot="description"
            onSubmit={handleSubmit}
            className="wpcf7-form" 
        >
<table>
    <tbody>
    <tr>
        <td className="lb"><label htmlFor="cf7title">{intl('support.form.title.label')}<span className="required">{required}</span></label></td>
    </tr>
    <tr>
        <td>
            <div className="form-group your-subject">
                <select className="wpcf7-form-control wpcf7-select wpcf7-validates-as-required form-control" id="cf7title" name="your-subject" required>
                    { titles.map((value) => <option value={value} key={value}>{value}</option>) }
                </select>
            </div>
            <br/>
        </td>
    </tr>
    <tr>
    <td className="lb"><label htmlFor="cf7name">{intl('support.form.name.label')}<span className="required">{required}</span></label></td>
    </tr>
    <tr>
        <td>
            <div className="form-group your-name">
                <input className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" id="cf7name" maxLength="50" name="name" type="text" onChange={handleChange} onBlur={handleBlur} value={values.name} placeholder={intl('support.form.name.placeHolder')} required />
            </div>
            <div className="validation">&nbsp;{errors.name && touched.name && <span>{intl(errors.name)}</span>}</div>
        </td>
    </tr>
    <tr>
    <td className="lb"><label htmlFor="cf7mail">{intl('support.form.email.label')}<span className="required">{required}</span></label></td>
    </tr>
    <tr>
        <td>
            <div className="form-group your-email">
                <input className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email form-control" id="cf7mail" maxLength="50" name="email" type="email" onChange={handleChange} onBlur={handleBlur} value={values.email} placeholder={intl('support.form.email.placeHolder')} required />
            </div>
            <div className="validation">&nbsp;{errors.email && touched.email && <span>{intl(errors.email)}</span>}</div>
        </td>
    </tr>
    <tr>
    <td className="lb"><label htmlFor="cf7text">{intl('support.form.message.label')}<span className="required">{required}</span></label></td>
    </tr>
    <tr>
        <td>
            <div className="form-group your-message">
                <textarea className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required form-control" id="cf7text" rows="4" maxLength="4000" name="message" onChange={handleChange} onBlur={handleBlur} value={values.message} required></textarea>
            </div>
            <div className="validation">&nbsp;{errors.message && touched.message && <span>{intl(errors.message)}</span>}</div>
        </td>
    </tr>
    <tr>
    <td className="lb"><label htmlFor="cf7os">{intl('support.form.os.label')}<span className="required">{required}</span></label></td>
    </tr>
    <tr>
        <td>
            <div className="form-group your-os">
                <select className="wpcf7-form-control wpcf7-select wpcf7-validates-as-required form-control" id="cf7os" name="your-os" required>
                    { iOSVersions.map((value) => <option value={'iOS ' + value} key={value}>iOS {value}</option>) }
                </select>
            </div>
            <br/>
        </td>
    </tr>

    <tr>
    <td className="lb"><label htmlFor="cf7ver">{intl('support.form.app.label')}<span className="required">{required}</span></label></td>
    </tr>
    <tr>
        <td>
            <div className="form-group your-ver">
                <select className="wpcf7-form-control wpcf7-select wpcf7-validates-as-required form-control" id="cf7ver" name="your-ver" required>
                    { AppVersions.map((value) => <option value={value} key={value}>{value}</option>) }
                </select>
            </div>
            <br/>
        </td>
    </tr>
    <tr>
        <td className="foot">
            <input name="description" className="description" />

            <span className="wpcf7-form-control-wrap wpgdprc">
                <span className="wpcf7-form-control wpcf7-validates-as-required wpcf7-wpgdprc">
                    <span className="wpcf7-list-item">
                        <label>
                        <input type="checkbox" name="agreement" onChange={handleChange} onBlur={handleBlur} value={values.agreement} required />
                        <span className="wpcf7-list-item-label">{intl('support.form.agreement')}</span>
                        </label>
                    </span>
                </span>
            </span>
            <div className="form-group">
                <input className={`wpcf7-form-control wpcf7-submit btn${dirty && isValid && !isSubmitting  ? " enabled" : "" }`}
                        value={intl('support.form.submit')} 
                        type="submit" 
                        disabled={!isValid || isSubmitting } />
            </div>
        </td>
    </tr>
    </tbody>
</table>
        </form>
      )}
    </Formik>
  </div>
  </div>
  </div>
        )
    }
}

export default injectIntl(Support)

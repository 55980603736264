import React, { Component } from 'react'
import twitter from '../img/twitter.svg'

class TwitterButton extends Component {
  constructor(props, context) {
    super(props, context)
  }

  openShareDialog = event => {
    event.preventDefault()
    event.nativeEvent.stopImmediatePropagation()
    const href = `https://twitter.com/share?url=https://www.newmonaural.com${this.props.pagePath}&text=${encodeURI(this.props.shareTitle)}`
    window.open(href, 'TWwindow', 'width=650, height=450, menubar=no, toolbar=no, scrollbars=yes')
  }
  render() {
    return (
      <a onClick={event => this.openShareDialog(event)} className={this.props.className}>
        <img src={twitter} alt="Share to Twitter" />
      </a>
    )
  }
}

export default TwitterButton 
